import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  detail: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  detail: (state) => state.detail,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearDetail')
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ commit }, params) {
    try {
      const response = await api.get('public/pages/list/', {
        params: params,
      })
      commit('setList', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * 指定のデータを取得する
   *
   * @param {string}
   * @return {void}
   */
  async fetchData({ commit }, permalink) {
    try {
      const response = await api.get('public/pages/' + permalink + '/')
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
