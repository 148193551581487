<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0, 0, 400, 80"
    :style="svgStyle"
  >
    <g id="layer">
      <path
        d="M112.965,29.801 C112.921,32.585 112.834,34.933 112.399,38.239 C105.919,37.499 101.396,40.283 100.222,47.328 C99.221,53.287 101.7,57.245 105.789,57.375 C109.05,57.462 110.921,56.114 112.791,52.113 C113.617,50.373 114.531,47.024 115.313,42.936 C117.575,43.588 119.402,44.24 121.75,45.458 C121.098,49.068 119.663,53.548 117.271,59.332 L121.576,59.332 C123.316,54.983 124.534,50.59 125.273,47.154 C126.404,47.894 127.883,48.807 129.318,49.764 L129.318,45.067 C128.318,44.371 127.056,43.675 126.012,43.11 C126.665,39.021 126.882,35.977 126.969,32.759 L123.142,32.759 C123.098,35.586 122.968,38.282 122.49,41.5 C120.489,40.413 117.879,39.5 116.053,38.934 C116.618,34.629 116.836,31.497 116.923,25.974 L102.657,25.974 L102.657,29.801 z M103.875,47.589 C104.788,43.371 107.006,41.5 111.877,42.196 C110.66,49.112 108.79,53.548 106.137,53.504 C104.223,53.461 103.223,50.677 103.875,47.589 z"
        :fill="fillColor"
      />
      <path
        d="M160.806,40.805 C160.893,49.155 156.37,53.983 151.064,54.418 L151.064,58.332 C160.241,57.81 165.112,49.59 164.807,41.109 C164.459,31.062 158.458,25.365 148.933,26.148 C140.887,26.974 135.45,34.455 135.32,43.371 C135.363,50.242 137.494,55.548 142.844,55.766 C150.542,56.07 151.803,44.98 151.803,30.323 C157.196,30.236 160.589,34.759 160.806,40.805 z M139.06,43.327 C139.06,37.238 142.713,31.149 147.976,30.41 C148.237,39.282 147.411,52.113 142.974,51.808 C140.365,51.634 139.06,48.329 139.06,43.327 z"
        :fill="fillColor"
      />
      <path
        d="M196.905,40.805 C196.992,49.155 192.468,53.983 187.162,54.418 L187.162,58.332 C196.339,57.81 201.21,49.59 200.906,41.109 C200.558,31.062 194.556,25.365 185.031,26.148 C176.985,26.974 171.549,34.455 171.418,43.371 C171.462,50.242 173.593,55.548 178.942,55.766 C186.64,56.07 187.902,44.98 187.902,30.323 C193.295,30.236 196.687,34.759 196.905,40.805 z M175.159,43.327 C175.159,37.238 178.812,31.149 184.074,30.41 C184.335,39.282 183.509,52.113 179.073,51.808 C176.463,51.634 175.159,48.329 175.159,43.327 z"
        :fill="fillColor"
      />
      <path
        d="M217.563,42.022 C220.564,37.76 223.565,34.933 226.74,34.759 C229.959,34.585 232.394,37.108 232.394,44.197 C232.394,49.547 229.002,54.461 222.217,54.809 L222.217,58.68 C231.698,58.332 236.091,51.678 236.221,44.371 C236.221,35.151 233.22,30.497 226.74,30.845 C222.869,31.062 219.694,33.846 217.563,36.586 L217.563,23.93 L213.78,23.93 L213.78,30.975 L207.56,30.975 L207.56,34.846 L213.78,34.846 L213.78,39.5 L207.56,47.85 L207.56,54.766 L213.78,45.806 L213.78,59.593 L217.563,59.593 z"
        :fill="fillColor"
      />
      <path
        d="M265.448,32.193 L265.622,31.671 L272.32,31.671 C271.624,36.542 270.537,40.718 268.928,44.24 C267.405,40.674 266.274,36.586 265.448,32.193 z M240.571,57.201 C241.527,57.854 242.789,59.028 243.441,59.854 C248.834,53.809 249.661,46.459 249.661,39.369 L254.227,39.369 C253.966,50.634 253.705,54.679 253.096,55.592 C252.792,56.07 252.444,56.201 251.922,56.201 C251.27,56.201 249.834,56.157 248.312,56.07 C248.878,57.027 249.182,58.636 249.269,59.724 C251.052,59.811 252.792,59.811 253.792,59.637 C254.967,59.463 255.749,59.115 256.445,58.027 C257.402,56.636 257.663,51.982 257.967,39.456 C258.968,40.152 260.36,41.327 260.925,41.892 C261.708,40.805 262.447,39.5 263.1,38.152 C264.013,41.892 265.187,45.371 266.666,48.416 C264.274,51.895 261.229,54.505 257.054,56.462 C257.75,57.288 258.881,59.202 259.185,60.072 C263.187,57.984 266.361,55.374 268.797,52.156 C270.928,55.374 273.581,57.984 276.974,59.811 C277.495,58.68 278.757,57.114 279.627,56.288 C276.104,54.592 273.364,51.939 271.189,48.416 C273.668,43.893 275.277,38.369 276.278,31.671 L279.322,31.671 L279.322,27.931 L266.666,27.931 C267.318,25.582 267.753,23.147 268.145,20.624 L264.143,19.972 C263.056,27.148 261.099,34.107 257.967,38.63 L258.011,37.412 C258.054,37.021 258.054,36.281 258.054,35.673 L249.661,35.673 L249.661,30.584 L259.664,30.584 L259.664,26.8 L252.444,26.8 L252.444,20.059 L248.486,20.059 L248.486,26.8 L241.44,26.8 L241.44,30.584 L245.92,30.584 L245.92,39.282 C245.92,45.11 245.268,51.678 240.571,57.201 z"
        :fill="fillColor"
      />
      <path
        d="M309.68,40.37 L320.074,40.37 L320.074,36.76 L308.592,36.76 L308.592,31.584 L318.769,31.584 L318.769,27.931 L312.724,27.931 C314.072,26.148 315.595,23.625 316.899,21.277 L312.898,19.928 C312.115,22.146 310.506,25.147 309.288,27.061 L311.68,27.931 L301.46,27.931 L303.765,26.887 C303.286,24.973 301.677,22.146 300.111,20.059 L296.762,21.538 C298.111,23.451 299.459,26.061 300.111,27.931 L295.371,27.931 L295.371,31.584 L304.548,31.584 L304.548,36.76 L294.022,36.76 L294.022,40.37 L304.156,40.37 C303.33,43.849 300.938,47.633 294.196,50.416 C295.066,51.112 296.241,52.504 296.719,53.374 C302.677,50.59 305.722,47.067 307.201,43.545 C309.288,48.329 312.637,51.721 317.682,53.504 C318.204,52.46 319.291,50.851 320.161,50.068 C314.899,48.633 311.463,45.241 309.68,40.37 z M282.236,55.331 L284.237,59.55 C284.672,59.202 285.02,58.897 285.368,58.593 C287.107,57.071 288.76,55.635 290.369,54.157 C292.892,57.462 296.501,58.897 301.764,59.115 C306.461,59.332 314.855,59.245 319.465,59.028 C319.726,57.767 320.335,55.809 320.77,54.896 C315.638,55.244 306.374,55.374 301.808,55.2 C297.284,55.027 293.935,53.635 292.065,50.634 L292.109,36.89 L282.932,36.89 L282.932,40.631 L288.238,40.631 L288.238,50.851 C286.237,52.504 284.106,54.113 282.236,55.331 z M283.193,23.451 C285.802,25.365 288.803,28.322 290.152,30.366 L293.196,27.67 C291.804,25.582 288.76,22.886 286.107,20.972 z"
        :fill="fillColor"
      />
      <path
        d="M338.515,46.894 L347.126,46.894 L347.126,51.895 L338.515,51.895 z M323.38,56.244 C324.206,56.723 325.902,58.071 326.511,58.897 C329.903,53.809 331.339,46.894 331.904,40.631 L355.955,40.631 C355.564,50.808 355.042,54.679 354.303,55.592 C353.911,56.07 353.563,56.244 352.911,56.201 C352.128,56.244 350.301,56.201 348.344,55.983 C348.953,57.027 349.344,58.68 349.388,59.811 C351.563,59.898 353.65,59.898 354.824,59.724 C356.086,59.593 356.999,59.245 357.825,58.158 C359.043,56.592 359.522,51.721 359.957,38.847 C360,38.456 360,37.673 360,37.064 L332.165,37.064 C332.252,35.933 332.296,34.846 332.296,33.802 L357.13,33.802 L357.13,22.146 L328.338,22.146 L328.338,32.541 C328.338,39.5 327.903,49.329 323.38,56.244 z M332.296,30.323 L332.296,25.582 L353.128,25.582 L353.128,30.323 z M334.862,57.68 L338.515,57.68 L338.515,55.157 L350.823,55.157 L350.823,43.675 L334.862,43.675 z"
        :fill="fillColor"
      />
      <path
        d="M46.306,38.855 L48.629,37.887 L43.887,71.468"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.306,71.565 L47.468,71.468"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.694,38.855 L62.371,37.887 L67.113,71.468"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M70.694,71.565 L63.532,71.468"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.855,43.21 L64.887,55.887"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.403,55.887 L66.339,67.5"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.952,43.21 L45.919,55.887"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M64.403,55.887 L44.468,67.5"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.919,27.435 L43.113,33.242"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.498,15.242 L50.47,18.145 L50.47,23.952 L55.498,26.855 L56.671,26.179 L56.671,23.729 L56.671,19.879 C56.671,19.231 56.147,18.706 55.498,18.706 C54.853,18.706 54.328,19.231 54.328,19.879 L54.328,23.728 C53.263,23.262 52.573,22.211 52.57,21.048 C52.57,19.431 53.881,18.121 55.498,18.121 C57.116,18.121 58.426,19.431 58.426,21.048 C58.426,22.132 57.824,23.126 56.865,23.633 L56.865,26.068 L60.53,23.952 L60.53,18.145 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.111,33.435 L32.083,36.338 L32.083,42.145 L37.111,45.048 L38.284,44.373 L38.284,41.923 L38.284,38.072 C38.284,37.424 37.76,36.899 37.111,36.899 C36.466,36.899 35.941,37.424 35.941,38.072 L35.941,41.922 C34.875,41.456 34.186,40.405 34.183,39.241 C34.183,37.625 35.494,36.315 37.111,36.315 C38.729,36.315 40.039,37.625 40.039,39.241 C40.039,40.326 39.437,41.32 38.477,41.827 L38.477,44.261 L42.143,42.145 L42.143,36.338 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.885,33.435 L68.857,36.338 L68.857,42.145 L73.885,45.048 L75.058,44.373 L75.058,41.923 L75.058,38.072 C75.058,37.424 74.534,36.899 73.885,36.899 C73.24,36.899 72.715,37.424 72.715,38.072 L72.715,41.922 C71.65,41.456 70.96,40.405 70.957,39.241 C70.957,37.625 72.268,36.315 73.885,36.315 C75.503,36.315 76.813,37.625 76.813,39.241 C76.813,40.326 76.211,41.32 75.252,41.827 L75.252,44.261 L78.917,42.145 L78.917,36.338 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M62.081,27.435 L67.887,33.242"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.887,30.339 C78.697,30.339 82.597,34.238 82.597,39.048 C82.597,43.859 78.697,47.758 73.887,47.758 L73.887,47.758"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M73.887,27.435 C80.301,27.435 85.5,32.635 85.5,39.048 C85.5,45.462 80.301,50.661 73.887,50.661 L73.887,50.661"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.113,30.532 C32.303,30.532 28.403,34.432 28.403,39.242 C28.403,44.052 32.303,47.952 37.113,47.952 L37.113,47.952"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.113,27.629 C30.699,27.629 25.5,32.828 25.5,39.242 C25.5,45.656 30.699,50.855 37.113,50.855 L37.113,50.855"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.79,21.048 C46.79,16.238 50.69,12.339 55.5,12.339 C60.31,12.339 64.21,16.238 64.21,21.048 L64.21,21.048"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M43.887,21.048 C43.887,14.635 49.086,9.435 55.5,9.435 C61.914,9.435 67.113,14.635 67.113,21.048 L67.113,21.048"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.5,37.887 C54.378,37.887 53.468,36.977 53.468,35.855 C53.468,34.732 54.378,33.823 55.5,33.823 C56.622,33.823 57.532,34.732 57.532,35.855 C57.532,36.977 56.622,37.887 55.5,37.887 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.5,39.605 C53.429,39.605 51.75,37.926 51.75,35.855 C51.75,33.784 53.429,32.105 55.5,32.105 C57.571,32.105 59.25,33.784 59.25,35.855 C59.25,37.926 57.571,39.605 55.5,39.605 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppLogoText',

  props: {
    svgWidth: {
      type: String,
      default: '400px',
    },
    svgHeight: {
      type: String,
      default: '80px',
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    fillColor: {
      type: String,
      default: '#fff',
    },
  },

  computed: {
    svgStyle() {
      return {
        width: this.svgWidth,
        height: this.svgHeight,
      }
    },
  },
}
</script>
