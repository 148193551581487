<template>
  <div :class="[fullScreen ? 'modal d-block' : 'player_wrapper']">
    <div
      :class="[
        fullScreen
          ? 'full-screen modal-dialog modal-dialog-centered modal-xl animate__animated animate__fadeIn'
          : 'p-2 m-3 mb-0 border border-secondary',
      ]"
    >
      <div>
        <MediaPlayer ref="MediaPlayer" @videoPlayEvent="showFullScreen" />
      </div>
    </div>
  </div>
  <div v-if="!fullScreen" class="animate__animated animate__fadeIn">
    <div class="min-vh-100" style="margin-left: 320px">
      <div
        class="position-fixed start-0 top-0 end-0 bg-dark"
        style="width: 320px"
      >
        <SideMenuDesktop />
      </div>

      <main>
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
import SideMenuDesktop from '@/layouts/SideMenuDesktop.vue'
import MediaPlayer from '@/components/MediaPlayer.vue'

export default {
  name: 'LayoutDesktop',
  components: {
    SideMenuDesktop,
    MediaPlayer,
  },

  data() {
    return {
      fullScreen: false,
    }
  },

  methods: {
    showFullScreen(toggle = false) {
      if (this.fullScreen != toggle) {
        this.fullScreen = toggle
      }
    },
  },
}
</script>

<style scoped>
.player_wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  width: 320px;
}

.player_wrapper > div {
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.full-screen > div {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}
</style>
