<template>
  <div :class="[showVideoPlayer ? 'ratio ratio-16x9' : 'd-none']">
    <video ref="videoPlayer" class="video-js" crossorigin="anonymous"></video>
  </div>
  <div class="d-flex align-items-center">
    <div v-if="!showVideoPlayer" class="flex-shrink-1 col-6 col-md-2 col-lg-6">
      <div class="ratio ratio-16x9 bg-dark">
        <img
          :src="
            displayImage(
              broadcast.program_file_path,
              broadcast.program_file_type
            )
          "
          class="img-fluid"
          :alt="broadcast.program_title"
        />
      </div>
    </div>
    <div class="w-100 px-2">
      <p class="m-0 p-0 small lh-sm">
        {{ textWrap(broadcast.program_title, 18) }}
      </p>
    </div>
    <div class="flex-shrink-1">
      <div class="player__operate pe-1">
        <a v-if="isPlaying" @click="pause" class="link-secondary">
          <i class="bi bi-stop-circle"></i>
        </a>
        <a v-else @click="play" class="link-secondary">
          <i class="bi bi-play-circle-fill"></i>
        </a>
      </div>
    </div>
  </div>
  <BroadcastNotify
    ref="BroadcastNotify"
    @onPublishDone="randomAudio"
    @onTimeout="randomAudio"
  />
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import videojs from 'video.js'
import { displayImage } from '@/utils/fileTypeUtils.js'
import { textWrap } from '@/utils/stringUtils.js'
import BroadcastNotify from '@/components/BroadcastNotify.vue'

import 'video.js/dist/video-js.css'

export default {
  name: 'MediaPlayer',
  components: {
    BroadcastNotify,
  },

  emits: ['playEvent', 'endedEvent', 'videoPlayEvent'],

  data() {
    return {
      isVideo: false,
    }
  },

  computed: {
    ...mapGetters({
      bgmList: 'public_bgm/list',
      broadcast: 'public_player/broadcast',
      isPlaying: 'public_player/isPlaying',
    }),
    showVideoPlayer() {
      return this.isVideo && this.isPlaying
    },
  },

  async mounted() {
    await this.createPlayer()
    await this.getData()
    await this.randomAudio()
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
  },
  beforeUnmount() {
    this.dispose()
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  },

  watch: {
    broadcast: {
      handler() {
        this.changeSource()
      },
    },
  },

  methods: {
    ...mapMutations({
      clearBroadcast: 'public_player/clearBroadcast',
    }),
    ...mapActions({
      getData: 'public_bgm/fetchList',
      updateBroadcast: 'public_player/updateBroadcast',
      updateIsPlaying: 'public_player/updateIsPlaying',
    }),
    displayImage,
    textWrap,
    createPlayer() {
      this.player = videojs(this.$refs.videoPlayer, {
        autoplay: false,
        controls: false,
        playsinline: true,
        preload: true,
      })
      this.player.on('play', () => {
        this.$emit('playEvent')
      })
      this.player.on('ended', () => {
        this.$emit('endedEvent')
        this.randomAudio()
      })
      this.player.on('error', () => {
        let error_code = this.player.error().code
        // MEDIA_ERR_SRC_NOT_SUPPORTED
        if (error_code == 4) {
          this.$emit('errorEvent')
        }
      })
    },
    sleep(ms = 1000) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, ms)
      })
    },
    async changeSource() {
      this.$debugLog('changeSource')
      if (this.player && this.broadcast?.file_url) {
        this.isVideo = this.broadcast?.program_broadcast_type === 'tv'

        if (!this.player.paused()) {
          this.player.pause()
        }

        this.player.src({
          src: this.broadcast.file_url,
          type: this.broadcast.file_type,
        })
        if (this.isPlaying) {
          await this.sleep(1000)
          this.play()
        }
      } else {
        this.$debugLog('changeSource: no source')
        this.isVideo = false
      }
    },
    async play() {
      this.$debugLog('mediaPlay')
      if (this.player) {
        if (!this.player.paused()) {
          await this.pause()
        }

        await this.player.src(this.player.src())
        let promise = this.player.play()
        if (promise === undefined) {
          this.updateIsPlaying(false)
          this.$emit('videoPlayEvent', false)
        } else {
          this.updateIsPlaying(true)
          if (this.isVideo) {
            this.$emit('videoPlayEvent', true)
          } else {
            this.$emit('videoPlayEvent', false)
          }
        }
      }
    },
    pause() {
      if (this.player && !this.player.paused()) {
        this.player.pause()
      }
      this.updateIsPlaying(false)
      this.$emit('videoPlayEvent', false)
    },
    dispose() {
      if (this.player) {
        this.player.dispose()
      }
    },
    async randomAudio() {
      this.$debugLog('randomAudio')
      // ランダムで選択されたファイルが直前のファイルと同じになると再生されないため一度クリアする
      await this.clearBroadcast()
      const randomIndex = Math.floor(Math.random() * this.bgmList.length)
      await this.updateBroadcast(this.bgmList[randomIndex])
    },
    async handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.$debugLog('フォアグラウンドに戻りました')
        // リロードする
        this.$router.go(0)
      } else if (document.visibilityState === 'hidden') {
        this.$debugLog('バックグラウンドに移動しました')
        await this.pause()
        await this.dispose()
        this.updateBroadcast({})
      }
    },
  },
}
</script>

<style scoped>
.player__operate i {
  font-size: 2rem !important;
}
</style>
