<template>
  <div v-if="pages && pages.length > 0" class="swiper swiper-banner">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- slides -->
      <div v-for="page in pages" :key="page.id" class="swiper-slide col-6">
        <div class="card rounded-0">
          <div class="row g-0">
            <div class="col">
              <div class="ratio ratio-1x1 bg-light">
                <router-link
                  :to="{
                    name: 'PublicPageDetail',
                    params: { permalink: page.permalink },
                  }"
                  class="stretched-link"
                >
                  <img
                    :src="displayImage(page.file_path, page.file_type)"
                    :alt="page.title"
                    class="card-img"
                /></router-link>
                <div class="card-img-overlay alpha-background d-md-none">
                  <h3 class="card-title">{{ page.title }}</h3>
                  <p class="card-text">{{ textWrap(page.excerpt, 15) }}</p>
                </div>
              </div>
            </div>
            <div class="col-md-8 d-none d-md-block">
              <div class="card-body">
                <h5 class="card-title">
                  <router-link
                    :to="{
                      name: 'PublicPageDetail',
                      params: { permalink: page.permalink },
                    }"
                    class="text-decoration-none text-body stretched-link"
                  >
                    {{ page.title }}</router-link
                  >
                </h5>
                <p class="card-text">
                  {{ textWrap(page.excerpt, 21) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swiper from 'swiper'
import { FreeMode, Mousewheel } from 'swiper/modules'
import { displayImage } from '@/utils/fileTypeUtils.js'
import { textWrap } from '@/utils/stringUtils.js'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/mousewheel'

export default {
  name: 'PageBanner',

  data() {
    return {
      swiper: null,
      swiperOptions: {
        modules: [FreeMode, Mousewheel],
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 5,
        freeMode: true,
        mousewheel: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      pages: 'public_page/list',
    }),
  },
  async mounted() {
    await this.getData({ category_name: 'ページバナー' })
    await this.initializeSwiper()
  },
  beforeUnmount() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  },

  methods: {
    displayImage,
    textWrap,
    ...mapActions({
      getData: 'public_page/fetchList',
    }),
    initializeSwiper() {
      this.swiper = new Swiper('.swiper-banner', this.swiperOptions)
    },
  },
}
</script>

<style scoped>
.alpha-background {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
