<template>
  <div class="p-3 vh-100">
    <div class="mb-5">
      <router-link
        :to="{
          name: 'PublicHome',
        }"
        ><AppLogoText svg-width="100%" svg-height="auto"
      /></router-link>
    </div>
    <SideMenuContent />
    <slot></slot>
  </div>
</template>

<script>
import SideMenuContent from '@/layouts/SideMenuContent.vue'
import AppLogoText from '@/components/AppLogoText.vue'

export default {
  name: 'SideMenuDesktop',
  components: {
    SideMenuContent,
    AppLogoText,
  },
}
</script>
