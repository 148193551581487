<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0"
    y="0"
    viewBox="0, 0, 80, 80"
    :style="svgStyle"
  >
    <g id="layer">
      <path
        d="M30.806,38.355 L33.129,37.387 L28.387,70.968"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.806,71.065 L31.968,70.968"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.194,38.355 L46.871,37.387 L51.613,70.968"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M55.194,71.065 L48.032,70.968"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.355,42.71 L49.387,55.387"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.903,55.387 L50.839,67"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M47.452,42.71 L30.419,55.387"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.903,55.387 L28.968,67"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M33.419,26.935 L27.613,32.742"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M39.998,14.742 L34.97,17.645 L34.97,23.452 L39.998,26.355 L41.171,25.679 L41.171,23.229 L41.171,19.379 C41.171,18.731 40.647,18.206 39.998,18.206 C39.353,18.206 38.828,18.731 38.828,19.379 L38.828,23.228 C37.763,22.762 37.073,21.711 37.07,20.548 C37.07,18.931 38.381,17.621 39.998,17.621 C41.616,17.621 42.926,18.931 42.926,20.548 C42.926,21.632 42.324,22.626 41.365,23.133 L41.365,25.568 L45.03,23.452 L45.03,17.645 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.611,32.935 L16.583,35.838 L16.583,41.645 L21.611,44.548 L22.784,43.873 L22.784,41.423 L22.784,37.572 C22.784,36.924 22.26,36.399 21.611,36.399 C20.966,36.399 20.441,36.924 20.441,37.572 L20.441,41.422 C19.375,40.956 18.686,39.905 18.683,38.741 C18.683,37.125 19.994,35.815 21.611,35.815 C23.229,35.815 24.539,37.125 24.539,38.741 C24.539,39.826 23.937,40.82 22.977,41.327 L22.977,43.761 L26.643,41.645 L26.643,35.838 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.385,32.935 L53.357,35.838 L53.357,41.645 L58.385,44.548 L59.558,43.873 L59.558,41.423 L59.558,37.572 C59.558,36.924 59.034,36.399 58.385,36.399 C57.74,36.399 57.215,36.924 57.215,37.572 L57.215,41.422 C56.15,40.956 55.46,39.905 55.457,38.741 C55.457,37.125 56.768,35.815 58.385,35.815 C60.003,35.815 61.313,37.125 61.313,38.741 C61.313,39.826 60.711,40.82 59.752,41.327 L59.752,43.761 L63.417,41.645 L63.417,35.838 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M46.581,26.935 L52.387,32.742"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.387,29.839 C63.197,29.839 67.097,33.738 67.097,38.548 C67.097,43.359 63.197,47.258 58.387,47.258 L58.387,47.258"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M58.387,26.935 C64.801,26.935 70,32.135 70,38.548 C70,44.962 64.801,50.161 58.387,50.161 L58.387,50.161"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.613,30.032 C16.803,30.032 12.903,33.932 12.903,38.742 C12.903,43.552 16.803,47.452 21.613,47.452 L21.613,47.452"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.613,27.129 C15.199,27.129 10,32.328 10,38.742 C10,45.156 15.199,50.355 21.613,50.355 L21.613,50.355"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.29,20.548 C31.29,15.738 35.19,11.839 40,11.839 C44.81,11.839 48.71,15.738 48.71,20.548 L48.71,20.548"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.387,20.548 C28.387,14.135 33.586,8.935 40,8.935 C46.414,8.935 51.613,14.135 51.613,20.548 L51.613,20.548"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40,37.387 C38.878,37.387 37.968,36.477 37.968,35.355 C37.968,34.232 38.878,33.323 40,33.323 C41.122,33.323 42.032,34.232 42.032,35.355 C42.032,36.477 41.122,37.387 40,37.387 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40,39.105 C37.929,39.105 36.25,37.426 36.25,35.355 C36.25,33.284 37.929,31.605 40,31.605 C42.071,31.605 43.75,33.284 43.75,35.355 C43.75,37.426 42.071,39.105 40,39.105 z"
        fill-opacity="0"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AppLogo',

  props: {
    svgWidth: {
      type: String,
      default: '80px',
    },
    svgHeight: {
      type: String,
      default: '80px',
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    fillColor: {
      type: String,
      default: '#fff',
    },
  },

  computed: {
    svgStyle() {
      return {
        width: this.svgWidth,
        height: this.svgHeight,
      }
    },
  },
}
</script>
