<template>
  <div class="container-fluid">
    <div
      class="d-flex align-items-center text-center"
      style="height: 50px; margin-left: 60px"
    >
      <div v-for="timetable in this.timetableData" class="flex-grow-1 col">
        {{ formatDay(timetable.date) }}
      </div>
    </div>
    <div id="timetableScrollbar">
      <div class="timetable" id="scheduleTimetable">
        <div class="timetable__timeline">
          <ul class="list-unstyled">
            <li><span>11:30</span></li>
            <li><span>12:00</span></li>
            <li><span>12:30</span></li>
            <li><span>13:00</span></li>
            <li><span>13:30</span></li>
            <li><span>14:00</span></li>
            <li><span>14:30</span></li>
            <li><span>15:00</span></li>
            <li><span>15:30</span></li>
            <li><span>16:00</span></li>
            <li><span>16:30</span></li>
            <li><span>17:00</span></li>
            <li><span>17:30</span></li>
            <li><span>18:00</span></li>
            <li><span>18:30</span></li>
            <li><span>19:00</span></li>
            <li><span>19:30</span></li>
            <li><span>20:00</span></li>
            <li><span>20:30</span></li>
            <li><span>21:00</span></li>
            <li><span>21:30</span></li>
          </ul>
        </div>
        <!-- .timetable__timeline -->

        <div class="timetable__events">
          <div>
            <div
              v-for="timetable in this.timetableData"
              class="timetable__group"
            >
              <div>
                <div
                  v-for="broadcast in timetable.broadcasts"
                  :key="broadcast.id"
                  class="timetable__event"
                >
                  <router-link
                    :to="{
                      name: 'PublicProgramDetail',
                      params: { permalink: broadcast.program.permalink },
                    }"
                    class="text-decoration-none text-body"
                    :data-start="formatTime(broadcast.start)"
                    :data-end="formatTime(broadcast.end)"
                    :style="getBackgroundColor(broadcast.color)"
                  >
                    <img
                      v-if="isTimeDifferenceGreaterThan30Minutes(broadcast)"
                      :src="
                        displayImage(
                          broadcast.program.file_path,
                          broadcast.program.file_type
                        )
                      "
                      class="img-fluid mb-1 mb-xl-2 d-none d-lg-block"
                    />
                    <span class="timetable__event__name"
                      ><span v-if="broadcast.program.broadcast_type == 'radio'"
                        ><i class="bi bi-mic"></i
                      ></span>
                      <span v-if="broadcast.program.broadcast_type == 'tv'"
                        ><i class="bi bi-tv"></i></span
                      >{{ broadcast.program.title }}</span
                    >
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- .timetable -->
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import PerfectScrollbar from 'perfect-scrollbar'
import { displayImage } from '@/utils/fileTypeUtils.js'
import ScheduleTimetable from '@/utils/timetable.js'

import 'perfect-scrollbar/css/perfect-scrollbar.css'
import '../assets/css/timetable.css'

export default {
  name: 'ProgramTimetableWeek',

  props: {
    timetableData: {
      type: Array,
      required: true,
    },
  },

  mounted() {
    this.initSchedule()
  },

  methods: {
    displayImage,
    initSchedule() {
      new ScheduleTimetable(document.querySelector('#scheduleTimetable'))
      const container = document.querySelector('#timetableScrollbar')
      const ps = new PerfectScrollbar(container)

      const currentHour = new Date().getHours()
      if (12 <= currentHour <= 21) {
        container.scrollTop = (currentHour - 12) * 2 * 200
        ps.update()
      }
    },
    getBackgroundColor(color) {
      return 'background-color: ' + color + ';'
    },
    formatDay(datetime) {
      const dayjs = inject('dayjs')
      return dayjs(datetime).format('D日')
    },
    formatTime(datetime) {
      const dayjs = inject('dayjs')
      return dayjs(datetime).format('HH:mm')
    },
    isTimeDifferenceGreaterThan30Minutes(broadcast) {
      const dayjs = inject('dayjs')
      const start = dayjs(broadcast.start)
      const end = dayjs(broadcast.end)
      const minutesDifference = end.diff(start, 'minutes')
      return minutesDifference >= 30
    },
  },
}
</script>

<style scoped>
#timetableScrollbar {
  position: relative;
  max-height: calc(100vh - 50px);
}
</style>
