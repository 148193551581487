// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  alert: {},
  timeout: 3000,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  alert: (state) => state.alert,
  timeout: (state) => state.timeout,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  set: (state, alert) => (state.alert = alert),
  clear: (state) => (state.alert = {}),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clear')
      resolve()
    })
  },
  setErrorMessage({ commit }, payload) {
    let data = {
      style: 'alert-danger',
      title: ('title' in payload) ? payload.title : '',
      message: ('message' in payload) ? payload.message : '',
    }
    commit('clear')
    commit('set', data)
  },
  setWarningMessage({ commit }, payload) {
    let data = {
      style: 'alert-warning',
      title: ('title' in payload) ? payload.title : '',
      message: ('message' in payload) ? payload.message : '',
    }
    commit('clear')
    commit('set', data)
  },
  setInfoMessage({ commit }, payload) {
    let data = {
      style: 'alert-info',
      title: ('title' in payload) ? payload.title : '',
      message: ('message' in payload) ? payload.message : '',
    }
    commit('clear')
    commit('set', data)
  },
  setSuccessMessage({ commit }, payload) {
    let data = {
      style: 'alert-success',
      title: ('title' in payload) ? payload.title : '',
      message: ('message' in payload) ? payload.message : '',
    }
    commit('clear')
    commit('set', data)
  },
  setSecondaryMessage({ commit }, payload) {
    let data = {
      style: 'alert-secondary',
      title: ('title' in payload) ? payload.title : '',
      message: ('message' in payload) ? payload.message : '',
    }
    commit('clear')
    commit('set', data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
