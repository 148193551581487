function ScheduleTimetable(element) {
  this.element = element
  this.timelineItems = this.element
    .getElementsByClassName('timetable__timeline')[0]
    .getElementsByTagName('li')
  this.timelineStart = getScheduleTimestamp(this.timelineItems[0].textContent)
  this.timelineUnitDuration =
    getScheduleTimestamp(this.timelineItems[1].textContent) -
    getScheduleTimestamp(this.timelineItems[0].textContent)
  this.singleEvents = this.element.getElementsByClassName('timetable__event')

  this.placeEvents()
}

ScheduleTimetable.prototype.placeEvents = function () {
  // on big devices - place events in the template according to their time/day
  var self = this,
    slotHeight = this.timelineItems[0].offsetHeight
  for (var i = 0; i < this.singleEvents.length; i++) {
    var anchor = this.singleEvents[i].getElementsByTagName('a')[0]
    var start = getScheduleTimestamp(anchor.getAttribute('data-start')),
      duration = getScheduleTimestamp(anchor.getAttribute('data-end')) - start

    var eventTop =
        (slotHeight * (start - self.timelineStart)) / self.timelineUnitDuration,
      eventHeight = (slotHeight * duration) / self.timelineUnitDuration

    this.singleEvents[i].setAttribute(
      'style',
      'top: ' + (eventTop - 1) + 'px; height: ' + (eventHeight + 1) + 'px'
    )
  }
}

function getScheduleTimestamp(time) {
  //accepts hh:mm format - convert hh:mm to timestamp
  time = time.replace(/ /g, '')
  var timeArray = time.split(':')
  var timeStamp = parseInt(timeArray[0]) * 60 + parseInt(timeArray[1])
  return timeStamp
}

export default ScheduleTimetable
