/**
 * ファイル種別に関するユーティリティ関数
 * @module fileTypeUtils
 */

/**
 * 指定されたファイルが画像かどうかを確認する
 *
 * @param {{ file_type?: string }} file - The file to check.
 * @returns {boolean} Whether the given file is an image or not.
 */
export const isImage = (file) => {
  if (!file || !file.file_type) return false
  return new RegExp(/image/).test(file.file_type.toLowerCase())
}

/**
 * 指定されたファイルが動画かどうかを確認する
 *
 * @param {{ file_type?: string }} file - The file to check.
 * @returns {boolean} Whether the given file is a video or not.
 */
export const isVideo = (file) => {
  if (!file || !file.file_type) return false
  return new RegExp(/video|mpegurl/).test(file.file_type.toLowerCase())
}

/**
 * MIMEタイプを取得する
 *
 * @param {string} filePath - MIMEタイプを取得する対象データ
 * @returns {string} ファイルのMIMEタイプ、MIMEタイプが見つからなかった場合は空文字を返す
 */
export const getMimeType = (filePath) => {
  if (!filePath) return

  if (isDataURL(filePath)) {
    return getMimeTypeFromDataURL(filePath)
  } else {
    return getMimeTypeFromFilePath(filePath)
  }
}

/**
 * ファイルのパスからMIMEタイプを取得する
 *
 * @param {string} filePath - MIMEタイプを取得する対象ファイルのパス
 * @returns {string} ファイルのMIMEタイプ、MIMEタイプが見つからなかった場合は空文字を返す
 */
export const getMimeTypeFromFilePath = (filePath) => {
  if (!filePath) return

  const fileParts = filePath.split('?')
  const ext = fileParts[0].split('.').pop().toLowerCase()
  const mimeTypes = {
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    webp: 'image/webp',
    mp4: 'video/mp4',
    webm: 'video/webm',
    m3u8: 'application/x-mpegURL',
  }
  return mimeTypes[ext] || ''
}

/**
 * dataURLからMIMEタイプを取得する
 *
 * @param {string} dataURL - MIMEタイプを取得する対象データ
 * @returns {string} ファイルのMIMEタイプ、MIMEタイプが見つからなかった場合は空文字を返す
 */
export const getMimeTypeFromDataURL = (dataURL) => {
  const mimeTypeRegex = /^data:(.+);base64,(.*)$/
  const result = dataURL.match(mimeTypeRegex)
  if (result && result.length > 1) {
    return result[1]
  } else {
    return ''
  }
}

export const isDataURL = (str) => {
  return /^data:/.test(str)
}

/**
 * MIMEタイプからランダムなファイル名を生成する
 *
 * @param {string} mimeType - MIMEタイプ
 * @returns {string} ファイル名
 */
export const generateRandomFileName = (mimeType) => {
  const ext = mimeType.split('/')[1].toLowerCase()
  const timestamp = new Date().getTime().toString(36)
  const randomString = Math.random().toString(36).substring(2, 10)
  return `${timestamp}${randomString}.${ext}`
}

/**
 * dataURLからファイル名を生成する
 *
 * @param {string} dataURL - MIMEタイプを取得する対象データ
 * @returns {string} ファイル名
 */
export const generateFileNameFromDataURL = (dataURL) => {
  const mimeType = getMimeTypeFromDataURL(dataURL)
  if (mimeType) {
    return generateRandomFileName(mimeType)
  } else {
    return ''
  }
}

/**
 * ファイルの有無を判別して画像を表示する
 *
 * @param {string} filePath - ファイルのパス
 * @param {string} mimeType - MIMEタイプ
 * @returns {string} 表示するファイルのパス
 */
export const displayImage = (filePath, mimeType) => {
  if (!filePath || !mimeType) {
    return require('@/assets/no_image.svg')
  }
  return filePath
}

export default {
  isImage,
  isVideo,
  getMimeType,
  getMimeTypeFromFilePath,
  getMimeTypeFromDataURL,
  isDataURL,
  generateRandomFileName,
  generateFileNameFromDataURL,
  displayImage,
}
