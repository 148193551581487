<template>
  <div v-if="broadcasts" class="swiper swiper-timetable">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- slides -->
      <div
        v-for="broadcast in broadcasts"
        :key="broadcast.id"
        class="swiper-slide col-6 col-md-4 col-xl-3 image-background"
        :style="getBackgroundImage(broadcast.program.file_path)"
      >
        <div class="ratio ratio-16x9 alpha-background">
          <div class="m-2 m-xl-3">
            <p class="text-body-secondary m-0 p-0 small">
              {{ formatTime(broadcast.start) }}
            </p>
            <p class="m-0 p-0 lh-sm">
              <router-link
                :to="{
                  name: 'PublicProgramDetail',
                  params: { permalink: broadcast.program.permalink },
                }"
                class="text-decoration-none text-body stretched-link"
              >
                <span v-if="broadcast.program.broadcast_type == 'radio'"
                  ><i class="bi bi-mic"></i
                ></span>
                <span v-if="broadcast.program.broadcast_type == 'tv'"
                  ><i class="bi bi-tv"></i
                ></span>
                {{ broadcast.program.title }}</router-link
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="broadcasts.length == 0"><slot></slot></div>
</template>

<script>
import { inject } from 'vue'
import Swiper from 'swiper'
import { FreeMode, Mousewheel } from 'swiper/modules'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/mousewheel'

export default {
  name: 'ProgramTimetableSlide',

  props: {
    broadcasts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      swiper: null,
      swiperOptions: {
        modules: [FreeMode, Mousewheel],
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: 3,
        freeMode: true,
        mousewheel: true,
      },
    }
  },

  mounted() {
    this.initializeSwiper()
  },
  beforeUnmount() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  },

  methods: {
    initializeSwiper() {
      this.swiper = new Swiper('.swiper-timetable', this.swiperOptions)
    },
    getBackgroundImage(image) {
      if (image) {
        return 'background-image: url(' + image + ');'
      }
    },
    formatTime(datetime) {
      const dayjs = inject('dayjs')
      return dayjs(datetime).format('HH:mm')
    },
  },
}
</script>

<style scoped>
.image-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.alpha-background {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
