import axios from 'axios'
import cookie from 'vue-cookies'
import store from '@/store'

const api = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

api.interceptors.request.use((request) => {
  const user = cookie.get(process.env.VUE_APP_AUTH_COOKIE_NAME)
  if (user?.provider_access_token) {
    request.headers.Authorization = `Token ${user.provider_access_token}`
  }

  store.commit('loading/set')
  return request
})

api.interceptors.response.use(
  (response) => {
    store.commit('loading/clear')
    return Promise.resolve(response)
  },
  (error) => {
    const statusCode = error.response?.status
    switch (statusCode) {
      case 400:
        // リクエストエラー
        break
      case 401:
        // 認証エラー
        break
      case 403:
        // 権限エラー
        store.dispatch('alert/setErrorMessage', {
          message: 'アクセス権限がありません。',
        })
        break
      case 404:
        // Not found
        store.dispatch('alert/setErrorMessage', {
          message: 'データがありません。',
        })
        break
      case 500:
        // サーバーエラー
        store.dispatch('alert/setErrorMessage', {
          title: error.response.statusText,
          message: 'サーバーエラーが発生しました。',
        })
        break
      default:
        if (
          error.code === 'ECONNABORTED' &&
          error.message.indexOf('timeout') !== -1
        ) {
          // タイムアウトエラー
          store.dispatch('alert/setErrorMessage', {
            message: '接続が遅いためタイムアウトしました。再度お試しください。',
          })
        } else {
          store.dispatch('alert/setErrorMessage', {
            message: '想定外のエラーが発生しました。',
          })
        }
        break
    }
    store.commit('loading/clear')
    return Promise.reject(error)
  }
)

export default api
