<template>
  <nav class="navbar bg-body fixed-top">
    <div class="container-fluid">
      <span class="m-0 p-0 w-100 text-center"
        ><router-link
          :to="{
            name: 'PublicHome',
          }"
          ><AppLogoText svg-width="auto" svg-height="32px" /></router-link
      ></span>
      <a
        class="position-absolute top-50 start-0 translate-middle-y ms-3"
        @click="toggleOffcanvas"
      >
        <i class="bi bi-list"></i>
      </a>
    </div>
  </nav>
  <div
    class="offcanvas offcanvas-start text-bg-dark"
    tabindex="-1"
    id="offcanvasMenu"
  >
    <div class="offcanvas-header">
      <a class="ms-1" @click="toggleOffcanvas">
        <i class="bi bi-x"></i>
      </a>
    </div>
    <div class="offcanvas-body">
      <SideMenuContent />
    </div>
  </div>
</template>

<script>
import { Offcanvas } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import SideMenuContent from '@/layouts/SideMenuContent.vue'
import AppLogoText from '@/components/AppLogoText.vue'

export default {
  name: 'SideMenuMobile',
  components: {
    SideMenuContent,
    AppLogoText,
  },

  mounted() {
    this.offcanvas = new Offcanvas(document.getElementById('offcanvasMenu'))

    this.$router.beforeEach((to, from, next) => {
      this.offcanvas.hide()
      next()
    })
  },
  methods: {
    toggleOffcanvas() {
      this.offcanvas.toggle()
    },
  },
}
</script>
