<template>
  <div v-if="isLoading">データを取得中...</div>
  <div v-else>
    <ProgramTimetableDayList v-if="isMobile" :timetable-data="slicedArray" />
    <ProgramTimetableWeek v-else :timetable-data="slicedArray" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import useScreenSize from '@/utils/screenSizeUtils.js'
import ProgramTimetableDayList from '@/components/ProgramTimetableDayList.vue'
import ProgramTimetableWeek from '@/components/ProgramTimetableWeek.vue'

export default {
  name: 'PublicProgramTimetable',
  components: {
    ProgramTimetableDayList,
    ProgramTimetableWeek,
  },

  setup() {
    const { screenSize } = useScreenSize()
    return { screenSize }
  },

  data() {
    return {
      isLoading: true,
      slicedArray: [],
    }
  },
  computed: {
    ...mapGetters({
      timetableData: 'public_broadcast/list',
    }),
    isMobile() {
      return (
        this.screenSize === 'xs' ||
        this.screenSize === 'sm' ||
        this.screenSize === 'md'
      )
    },
  },

  async mounted() {
    await this.getData()
      .then(() => {
        if (this.isMobile) {
          this.slicedArray = this.timetableData.slice(0, 3)
        } else {
          this.slicedArray = this.timetableData
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    ...mapActions({
      getData: 'public_broadcast/fetchList',
    }),
  },
}
</script>
