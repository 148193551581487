<template>
  <div v-if="isLoading">
    <div class="ratio ratio-16x9 bg-dark">
      <img :src="displayImage()" />
    </div>
  </div>
  <div v-else class="swiper swiper-recommend p-lg-5">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- slides -->
      <div
        v-for="program in program_list"
        :key="program.id"
        class="swiper-slide"
      >
        <div class="ratio ratio-16x9 bg-dark">
          <img
            :src="displayImage(program.file_path, program.file_type)"
            :alt="program.title"
            @click.prevent="moveToProgram(program.permalink)"
            loading="lazy"
          />
        </div>
        <div class="swiper-lazy-preloader"></div>
        <figcaption class="figure-caption text-center mt-2 d-none d-lg-block">
          {{ program.title }}
        </figcaption>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Swiper from 'swiper'
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules'
import { displayImage } from '@/utils/fileTypeUtils.js'
import useScreenSize from '@/utils/screenSizeUtils.js'

// import Swiper and modules styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

export default {
  name: 'ProgramRecommend',

  setup() {
    const { screenSize } = useScreenSize()
    return { screenSize }
  },

  data() {
    return {
      isLoading: true,
      swiper: null,
      swiperOptions: {
        modules: [Autoplay, EffectCoverflow, Pagination],
        autoplay: {
          delay: 3000,
        },
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 1,
        spaceBetween: 0,
      },
    }
  },

  computed: {
    ...mapGetters({
      program_list: 'public_program/list',
    }),
  },

  async mounted() {
    await this.getData().finally(() => {
      this.isLoading = false
    })
    await this.initializeSwiper()
  },
  beforeUnmount() {
    if (this.swiper) {
      this.swiper.destroy()
    }
  },

  methods: {
    displayImage,
    ...mapActions({
      getData: 'public_program/fetchList',
    }),
    initializeSwiper() {
      if (
        this.screenSize === 'lg' ||
        this.screenSize === 'xl' ||
        this.screenSize === 'xxl'
      ) {
        this.swiperOptions.slidesPerView = 'auto'
        this.swiperOptions.spaceBetween = 30
        this.swiperOptions.effect = 'coverflow'
        this.swiperOptions.coverflowEffect = {
          slideShadows: false,
        }
        this.swiperOptions.pagination = {
          el: '.swiper-pagination',
          clickable: true,
        }
      }
      this.swiper = new Swiper('.swiper-recommend', this.swiperOptions)
    },
    moveToProgram(permalink) {
      this.$router.push({
        name: 'PublicProgramDetail',
        params: { permalink: permalink },
      })
    },
  },
}
</script>

<style>
@media screen and (min-width: 992px) {
  .swiper-recommend .swiper-slide {
    width: 70%;
    height: auto;
  }
}

.swiper-recommend .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.3;
}
.swiper-recommend .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
</style>
