<template></template>
<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import {
  getDatabase,
  ref as dbRef,
  query as dbQuery,
  off,
  orderByChild,
  startAt,
  onChildAdded,
  onChildRemoved,
  connectDatabaseEmulator,
} from 'firebase/database'

export default {
  name: 'BroadcastNotify',

  emits: ['onPublishDone', 'onTimeout'],

  data() {
    return {
      dataKey: null,
      broadcast: {},
      timeout: 5000, // 5秒
      startTime: null,
      currentAddedListener: null,
      currentRemovedListener: null,
    }
  },

  mounted() {
    const db = this.getFirebaseDatabase()
    this.dataRef = dbRef(db, 'broadcasts')
    this.setupChildAddedListener()
    this.setupChildRemovedListener()
  },
  beforeUnmount() {
    this.removeChildAddedListener()
    this.removeChildRemovedListener()
  },

  methods: {
    ...mapActions({
      updateBroadcast: 'public_player/updateBroadcast',
    }),
    getFirebaseDatabase() {
      const database = getDatabase()
      if (process.env.NODE_ENV === 'development') {
        this.$debugLog('connect to database emulator')
        connectDatabaseEmulator(database, 'localhost', 9000)
      }
      return database
    },
    removeChildAddedListener() {
      if (this.currentAddedListener) {
        off(this.dataRef, 'child_added', this.currentAddedListener)
        this.currentAddedListener = null
      }
    },
    setupChildAddedListener() {
      // 既存のリスナーがある場合はクリーンアップ
      this.removeChildAddedListener()

      // 終了日時が現在日時以降のデータを1件取得（昇順）
      const now = new Date().getTime()
      const dataQuery = dbQuery(
        this.dataRef,
        orderByChild('end_time'),
        startAt(now)
      )

      const listener = (dataSnapshot) => {
        this.setBroadcast(dataSnapshot)
      }

      // 新しいデータが追加されたとき
      onChildAdded(dataQuery, listener, (error) => {
        this.$debugLog('onChildAdded', error)
        this.$emit('onTimeout')
      })

      this.currentAddedListener = listener
    },
    removeChildRemovedListener() {
      if (this.currentRemovedListener) {
        off(this.dataRef, 'child_removed', this.currentRemovedListener)
        this.currentRemovedListener = null
      }
    },
    setupChildRemovedListener() {
      // 既存のリスナーがある場合はクリーンアップ
      this.removeChildRemovedListener()

      const listener = (snapshot) => {
        if (this.dataKey === snapshot.key) {
          this.$emit('onPublishDone')
        }
      }

      onChildRemoved(this.dataRef, listener, (error) => {
        this.$debugLog('onChildRemoved', error)
      })

      this.currentRemovedListener = listener
    },
    setBroadcast(snapshot) {
      if (snapshot.exists()) {
        this.$debugLog(snapshot.key, snapshot.val())
        this.dataKey = snapshot.key
        this.broadcast = snapshot.val()
        this.startTime = Date.now()
        this.checkBroadcast()
      } else {
        this.$debugLog('No data available')
      }
    },
    async checkBroadcast() {
      try {
        const response = await axios.get(this.broadcast.file_url)
        if (
          response.status === 200 &&
          // iOS対応のため150バイト以上ある場合は配信開始とみなす
          response.headers['content-length'] >= 150
        ) {
          this.updateBroadcast(this.broadcast)
        } else {
          if (Date.now() - this.startTime >= this.timeout) {
            this.$emit('onTimeout')
          } else {
            setTimeout(() => this.checkBroadcast(), 1000)
          }
        }
      } catch (error) {
        if (Date.now() - this.startTime >= this.timeout) {
          this.$emit('onTimeout')
        } else {
          setTimeout(() => this.checkBroadcast(), 1000)
        }
      }
    },
  },
}
</script>
