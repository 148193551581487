<template>
  <button class="btn btn-circle corporate-twitter-x" @click="shareOnTwitter">
    <i class="bi bi-twitter-x"></i>
  </button>
  <button class="btn btn-circle corporate-facebook" @click="shareOnFacebook">
    <i class="bi bi-facebook"></i>
  </button>
  <button class="btn btn-circle corporate-linkedin" @click="shareOnLinkedIn">
    <i class="bi bi-linkedin"></i>
  </button>
  <button class="btn btn-circle corporate-line" @click="shareOnLINE">
    <i class="bi bi-chat-fill"></i>
  </button>
  <slot></slot>
</template>

<script>
export default {
  name: 'SocialMediaShareButtons',

  props: {
    share_url: {
      type: String,
      required: true,
    },
  },

  methods: {
    shareOnTwitter() {
      const shareUrl =
        'https://twitter.com/share?url=' + encodeURIComponent(this.share_url)
      window.open(shareUrl, '_blank')
    },
    shareOnFacebook() {
      const shareUrl =
        'https://www.facebook.com/sharer.php?u=' +
        encodeURIComponent(this.share_url)
      window.open(shareUrl, '_blank')
    },
    shareOnLinkedIn() {
      const shareUrl =
        'https://www.linkedin.com/shareArticle?url=' +
        encodeURIComponent(this.share_url)
      window.open(shareUrl, '_blank')
    },
    shareOnLINE() {
      const shareUrl =
        'https://social-plugins.line.me/lineit/share?url=' +
        encodeURIComponent(this.share_url)
      window.open(shareUrl, '_blank')
    },
  },
}
</script>

<style scoped>
.btn-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.corporate-twitter {
  background-color: #00acee;
}

.corporate-twitter-x {
  background-color: #000;
}

.corporate-facebook {
  background-color: #1877f2;
}

.corporate-linkedin {
  background-color: #0077b5;
}

.corporate-line {
  background-color: #00c300;
}
</style>
