import { createStore } from 'vuex'
import auth from './modules/auth'
import alert from './modules/alert'
import loading from './modules/loading'
import public_program from './public/program'
import public_broadcast from './public/broadcast'
import public_bgm from './public/bgm'
import public_player from './public/player'
import public_page from './public/page'
import public_notify from './public/notify'

const store = createStore({
  actions: {
    clearAllStores({ dispatch }) {
      dispatch('auth/clearAll', null, { root: true })
      dispatch('alert/clearAll', null, { root: true })
      dispatch('loading/clearAll', null, { root: true })
      dispatch('public_program/clearAll', null, { root: true })
      dispatch('public_broadcast/clearAll', null, { root: true })
      dispatch('public_bgm/clearAll', null, { root: true })
      dispatch('public_player/clearAll', null, { root: true })
      dispatch('public_page/clearAll', null, { root: true })
      dispatch('public_notify/clearAll', null, { root: true })
    },
    clearDataStores({ dispatch }) {
      dispatch('public_program/clearAll', null, { root: true })
      dispatch('public_broadcast/clearAll', null, { root: true })
      dispatch('public_bgm/clearAll', null, { root: true })
      dispatch('public_player/clearAll', null, { root: true })
      dispatch('public_page/clearAll', null, { root: true })
      dispatch('public_notify/clearAll', null, { root: true })
    },
  },
  // @see https://vuex.vuejs.org/ja/guide/modules.html
  modules: {
    auth,
    alert,
    loading,
    public_program,
    public_broadcast,
    public_bgm,
    public_player,
    public_page,
    public_notify,
  },
})

export default store
