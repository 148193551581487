<template>
  <div class="container-fluid">
    <div v-if="!isLoading" class="mb-3 mx-md-2 mx-xl-3">
      <div class="row gy-3">
        <div class="col-12">
          <div class="ratio ratio-16x9 bg-dark">
            <img
              :src="displayImage(program.file_path, program.file_type)"
              class="card-img-top"
              :alt="program.title"
            />
          </div>
        </div>
        <div class="col-12">
          <h5>
            <span v-if="program.broadcast_type == 'radio'"
              ><i class="bi bi-mic"></i
            ></span>
            <span v-if="program.broadcast_type == 'tv'"
              ><i class="bi bi-tv"></i
            ></span>
            {{ program.title }}
          </h5>
          <p class="p-0 m-0">
            <span
              :class="
                program.type === 'premium' ? 'text-bg-primary' : 'text-bg-light'
              "
              class="badge"
              >{{ program.type_name }}</span
            >
          </p>
          <p class="p-0 m-0 mt-1 small">{{ program.short_message }}</p>
        </div>
        <div class="col-12">
          <div class="card card-body">
            <div class="text-pre-line">{{ program.description }}&nbsp;</div>
          </div>
        </div>
        <div
          class="col-8 offset-2 col-md-4 offset-md-4 d-flex justify-content-around"
        >
          <SocialMediaShareButtons :share_url="program_url">
            <button
              v-if="isLoggedIn"
              class="btn rounded-circle btn-secondary"
              @click="addProgramNotify(program.id)"
            >
              <i class="bi bi-bell-fill"></i>
            </button>
          </SocialMediaShareButtons>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { displayImage } from '@/utils/fileTypeUtils.js'
import SocialMediaShareButtons from '@/components/SocialMediaShareButtons.vue'

export default {
  name: 'PublicProgramDetail',
  components: {
    SocialMediaShareButtons,
  },

  props: {
    permalink: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      program: 'public_program/detail',
      isLoggedIn: 'auth/isLoggedIn',
    }),
    program_url() {
      return window.location.origin + '/program/' + this.program.permalink
    },
  },
  watch: {
    permalink: function () {
      this.getPageData()
    },
  },

  async created() {
    await this.getPageData()
  },

  methods: {
    displayImage,
    ...mapActions({
      getData: 'public_program/fetchData',
      addNotify: 'public_notify/postData',
    }),
    async getPageData() {
      try {
        await this.getData(this.permalink)
      } catch (error) {
        this.$router.push({
          name: 'PublicProgramList',
        })
      } finally {
        this.isLoading = false
      }
    },
    addProgramNotify(program_id) {
      this.addNotify(program_id).then(() => {
        this.$store.dispatch('alert/setSuccessMessage', {
          message: 'この番組の放送開始通知を登録しました。',
        })
      })
    },
  },
}
</script>

<style scoped>
.text-pre-line {
  white-space: pre-line;
}
</style>
