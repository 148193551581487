import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  data: {},
  detail: {},
  query_params: {},
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  programs: (state) => state.data.results,
  count: (state) => state.data.count,
  prev: (state) => state.data.previous,
  next: (state) => state.data.next,
  current_page: (state) => state.data.current_page,
  total_pages: (state) => state.data.total_pages,
  detail: (state) => state.detail,
  query_params: (state) => state.query_params,
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setData: (state, data) => (state.data = data),
  clearData: (state) => (state.data = {}),
  setDetail: (state, detail) => (state.detail = detail),
  clearDetail: (state) => (state.detail = {}),
  setQueryParams: (state, query_params) => (state.query_params = query_params),
  clearQueryParams: (state) => (state.query_params = {}),
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearData')
      commit('clearDetail')
      commit('clearQueryParams')
      commit('clearList')
      resolve()
    })
  },
  /**
   * 一覧を取得する
   *
   * @return {void}
   */
  async fetchAll({ commit, getters }) {
    try {
      const response = await api.get('public/programs/', {
        params: getters.query_params,
      })
      commit('setData', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * 検索クエリを保存する
   *
   * @param {object}
   * @return {void}
   */
  setQuery({ commit }, query_params) {
    return new Promise((resolve) => {
      commit('setQueryParams', query_params)
      resolve()
    })
  },
  /**
   * 指定のデータを取得する
   *
   * @param {string}
   * @return {void}
   */
  async fetchData({ state, commit }, permalink) {
    if (
      Object.keys(state.detail).length > 0 &&
      state.detail.permalink == permalink
    ) {
      return
    }

    try {
      const response = await api.get('public/programs/' + permalink + '/')
      commit('setDetail', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ commit }) {
    try {
      const response = await api.get('public/programs/list/')
      commit('setList', response.data)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
