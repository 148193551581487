<template>
  <keep-alive>
    <component :is="layoutComponent">
      <router-view></router-view>
    </component>
  </keep-alive>
</template>

<script>
import useScreenSize from '@/utils/screenSizeUtils.js'
import LayoutDesktop from '@/layouts/PublicDesktop.vue'
import LayoutMobile from '@/layouts/PublicMobile.vue'

export default {
  name: 'PublicLayout',
  components: {
    LayoutDesktop,
    LayoutMobile,
  },

  setup() {
    const { screenSize } = useScreenSize()
    return { screenSize }
  },

  data() {
    return {
      layoutComponent: null,
    }
  },
  computed: {
    isMobile() {
      return (
        this.screenSize === 'xs' ||
        this.screenSize === 'sm' ||
        this.screenSize === 'md'
      )
    },
  },

  mounted() {
    this.layoutComponent = this.isMobile ? 'LayoutMobile' : 'LayoutDesktop'
  },
}
</script>

<style>
.ratio-16x9 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ratio-1x1 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
