import api from '@/api'
import cookie from 'vue-cookies'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  // listener 情報を格納する
  user: {},
  lineAuthUrl: '',
  lineNotifyAuthUrl: '',
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  hasCookie() {
    return cookie.isKey(process.env.VUE_APP_AUTH_COOKIE_NAME)
  },
  isLoggedIn(state) {
    return !!Object.keys(state.user).length
  },
  user(state) {
    return state.user
  },
  lineAuthUrl(state) {
    return state.lineAuthUrl
  },
  lineNotifyAuthUrl(state) {
    return state.lineNotifyAuthUrl
  },
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setUser: (state, user) => (state.user = user),
  clearUser: (state) => (state.user = {}),
  setToken: (state, data) => {
    cookie.set(process.env.VUE_APP_AUTH_COOKIE_NAME, data)
  },
  clearToken: (state) => {
    cookie.remove(process.env.VUE_APP_AUTH_COOKIE_NAME)
  },
  setLineAuthUrl: (state, url) => (state.lineAuthUrl = url),
  clearLineAuthUrl: (state) => (state.lineAuthUrl = ''),
  setLineNotifyAuthUrl: (state, url) => (state.lineNotifyAuthUrl = url),
  clearLineNotifyAuthUrl: (state) => (state.lineNotifyAuthUrl = ''),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearToken')
      commit('clearUser')
      commit('clearLineAuthUrl')
      commit('clearLineNotifyAuthUrl')
      resolve()
    })
  },
  /**
   * ユーザー情報を取得する
   *
   * @return {void}
   */
  async renew({ commit }) {
    const user = cookie.get(process.env.VUE_APP_AUTH_COOKIE_NAME)
    commit('setUser', user)
  },
  /**
   * LINE Auth URLを取得する
   *
   * @param {object} params
   * @return {void}
   */
  async getLineAuthUrl({ state, commit }, params) {
    if (state.lineAuthUrl) return

    try {
      const response = await api.post('public/notify/line-auth/', params)
      commit('setLineAuthUrl', response.data.auth_url)
    } catch (error) {
      throw error
    }
  },
  /**
   * LINE Profile を取得する
   *
   * @param {object} params
   * @return {void}
   */
  async getLineProfile({ commit }, params) {
    try {
      const response = await api.post('public/notify/line-profile/', params)
      commit('setUser', response.data)
      commit('setToken', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * LINE Notify Auth URLを取得する
   *
   * @param {object} params
   * @return {void}
   */
  async getLineNotifyAuthUrl({ state, commit }, params) {
    if (state.lineNotifyAuthUrl) return

    try {
      const response = await api.post('public/notify/line-notify-auth/', params)
      commit('setLineNotifyAuthUrl', response.data.auth_url)
    } catch (error) {
      throw error
    }
  },
  /**
   * LINE Notify access token を取得する
   *
   * @param {object} params
   * @return {void}
   */
  async getLineNotifyAccessToken({ state, commit }, params) {
    try {
      await api.post('public/notify/line-notify-token/', params)
      state.user['has_notify_token'] = true
      commit('setToken', state.user)
    } catch (error) {
      throw error
    }
  },
  /**
   * ヘルスチェック
   *
   * @return {void}
   */
  async boot({}) {
    await api.get('health-check/')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
