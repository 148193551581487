import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ commit }) {
    try {
      const response = await api.get('public/notify/')
      commit('setList', response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを追加する
   *
   * @param {integer}
   * @return {void}
   */
  async postData({ state }, program_id) {
    const params = {
      program_id: program_id,
    }
    try {
      const response = await api.post('public/notify/add/', params)
      state.list.push(response.data)
    } catch (error) {
      throw error
    }
  },
  /**
   * データを削除する
   *
   * @param {number}
   * @return {void}
   */
  async deleteData({ state }, program_id) {
    const params = {
      program_id: program_id,
    }
    try {
      await api.post('public/notify/delete/', params)
      state.list = state.list.filter((item) => item.id !== program_id)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
