<template>
  <div class="container-fluid">
    <div v-if="!isLoading" class="py-3 mx-md-2 mx-xl-3">
      <h4 class="py-2 py-md-3">
        {{ page.title }}
      </h4>
      <div class="row gy-3">
        <div class="col-12">
          <img
            v-if="page.file_path"
            :src="displayImage(page.file_path, page.file_type)"
            class="col-md-5 float-md-end mb-3 ms-md-3 img-fluid"
            :alt="page.title"
          />
          <div class="text-pre-line">{{ page.content }}&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { displayImage } from '@/utils/fileTypeUtils.js'

export default {
  name: 'PublicPageDetail',
  components: {},

  props: {
    permalink: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      page: 'public_page/detail',
    }),
  },
  watch: {
    permalink: function () {
      this.getPageData()
    },
  },

  created() {
    this.getPageData()
  },

  methods: {
    displayImage,
    ...mapActions({
      getData: 'public_page/fetchData',
    }),
    async getPageData() {
      await this.getData(this.permalink)
        .catch(() => {
          window.setTimeout(() => {
            this.$router.push({
              name: 'PublicHome',
            })
          }, this.$store.getters['alert/timeout'])
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style scoped>
.text-pre-line {
  white-space: pre-line;
}
</style>
