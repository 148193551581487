import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ commit }) {
    const bgm = [
      {
        file_url: '/audio/sample01/audio.m3u8',
        file_type: 'application/x-mpegURL',
      },
      {
        file_url: '/audio/sample02/audio.m3u8',
        file_type: 'application/x-mpegURL',
      },
    ]
    bgm.forEach(
      (item) => (item.program_title = 'ただいま放送中の番組はありません')
    )
    commit('setList', bgm)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
