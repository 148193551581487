import { ref, onMounted, onUnmounted } from 'vue'

export default function useScreenSize() {
  const screenSize = ref('')

  function handleScreenSizeChange() {
    const screenWidth = window.innerWidth
    if (screenWidth > 1400) {
      screenSize.value = 'xxl'
    } else if (screenWidth >= 1200) {
      screenSize.value = 'xl'
    } else if (screenWidth >= 992) {
      screenSize.value = 'lg'
    } else if (screenWidth >= 768) {
      screenSize.value = 'md'
    } else if (screenWidth >= 576) {
      screenSize.value = 'sm'
    } else {
      screenSize.value = 'xs'
    }
  }

  onMounted(() => {
    handleScreenSizeChange()
    window.addEventListener('resize', handleScreenSizeChange)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleScreenSizeChange)
  })

  return { screenSize }
}
