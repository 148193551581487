<template>
  <div :class="[fullScreen ? 'modal d-block' : 'player_wrapper']">
    <div
      :class="[
        fullScreen
          ? 'full-screen modal-dialog modal-dialog-centered animate__animated animate__fadeIn'
          : 'p-2 m-3 mb-0 border border-secondary bg-body',
      ]"
    >
      <div>
        <MediaPlayer ref="MediaPlayer" @videoPlayEvent="showFullScreen" />
      </div>
    </div>
  </div>
  <div v-if="!fullScreen" class="animate__animated animate__fadeIn">
    <div class="min-vh-100">
      <SideMenuMobile />

      <main style="padding-top: 50px; padding-bottom: 100px">
        <slot></slot>
      </main>
    </div>
  </div>
</template>

<script>
import SideMenuMobile from '@/layouts/SideMenuMobile.vue'
import MediaPlayer from '@/components/MediaPlayer.vue'

export default {
  name: 'LayoutMobile',
  components: {
    SideMenuMobile,
    MediaPlayer,
  },

  data() {
    return {
      fullScreen: false,
    }
  },

  methods: {
    showFullScreen(toggle = false) {
      if (this.fullScreen != toggle) {
        this.fullScreen = toggle
      }
    },
  },
}
</script>

<style scoped>
.player_wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.player_wrapper > div {
  border-radius: 5px;
  margin-bottom: 20px !important;
}

.full-screen > div {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  outline: 0;
}
</style>
