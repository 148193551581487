import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { debugLog } from '@/utils/debugUtils.js'

// Import Bootstrap an BootstrapIcon CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootswatch/dist/superhero/bootstrap.min.css'

// Import animate CSS file
import 'animate.css/animate.min.css'

// Import dayjs files (order is important)
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
// ロケール設定
dayjs.locale('ja')

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
}
const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)

const app = createApp(App)
app.config.globalProperties.$debugLog = debugLog

app.use(router)
app.use(store)
app.use(VueCookies, {
  expireTimes: '1d',
  path: '/',
  domain: '',
  secure: true,
  sameSite: 'Lax',
})
app.provide('dayjs', dayjs)
app.mount('#app')
