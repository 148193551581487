<template>
  <div class="container-fluid">
    <div class="mt-3 mx-md-2 mx-xl-3">
      <h4 class="py-2 py-md-3">
        <i class="bi bi-calendar-week me-2"></i>番組表
      </h4>
      <div class="row gy-4">
        <div v-for="timetable in this.timetableData">
          <div class="col">
            <h5>{{ timetable.date }}</h5>
            <ProgramTimetableSlide :broadcasts="timetable.broadcasts">
              <p>放送予定はありません。</p>
            </ProgramTimetableSlide>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramTimetableSlide from './ProgramTimetableSlide.vue'

export default {
  name: 'ProgramTimetableDayList',
  components: {
    ProgramTimetableSlide,
  },

  props: {
    timetableData: {
      type: Array,
      required: true,
    },
  },
}
</script>
