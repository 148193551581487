<template>
  <ProgramTimetableSlide v-if="!isLoading" :broadcasts="broadcasts">
    <slot></slot>
  </ProgramTimetableSlide>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProgramTimetableSlide from './ProgramTimetableSlide.vue'

export default {
  name: 'ProgramTimetableDay',
  components: {
    ProgramTimetableSlide,
  },

  data() {
    return {
      isLoading: true,
      broadcasts: [],
    }
  },
  computed: {
    ...mapGetters({
      timetableData: 'public_broadcast/list',
    }),
  },

  async mounted() {
    await this.getData()
      .then(() => {
        this.broadcasts = this.timetableData.shift().broadcasts
      })
      .finally(() => {
        this.isLoading = false
      })
  },

  methods: {
    ...mapActions({
      getData: 'public_broadcast/fetchList',
    }),
  },
}
</script>
