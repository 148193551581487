// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  broadcast: {},
  isPlaying: false,
  showPlayer: false,
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  broadcast: (state) => state.broadcast,
  isPlaying: (state) => state.isPlaying,
  showPlayer: (state) => state.showPlayer,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setBroadcast: (state, broadcast) => (state.broadcast = broadcast),
  clearBroadcast: (state) => (state.broadcast = {}),
  setIsPlaying: (state, isPlaying) => (state.isPlaying = isPlaying),
  clearIsPlaying: (state) => (state.isPlaying = false),
  setShowPlayer: (state, showPlayer) => (state.showPlayer = showPlayer),
  clearShowPlayer: (state) => (state.showPlayer = false),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearBroadcast')
      commit('clearIsPlaying')
      commit('clearShowPlayer')
      resolve()
    })
  },
  /**
   * ソースを更新する
   *
   * @param {Object} broadcast
   * @return {void}
   */
  async updateBroadcast({ commit }, broadcast) {
    commit('setBroadcast', broadcast)
  },
  /**
   * 再生状態を更新する
   *
   * @param {Boolean} isPlaying
   * @return {void}
   */
  async updateIsPlaying({ state, commit }, isPlaying) {
    commit('setIsPlaying', isPlaying)
    if (state.broadcast.program_broadcast_type == 'tv') {
      commit('setShowPlayer', isPlaying)
    } else {
      commit('setShowPlayer', false)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
