import { createRouter, createWebHistory } from 'vue-router'
import AppLogin from '@/pages/AppLogin.vue'
import NotFound from '@/pages/NotFound.vue'

import Public from './public'

// store
import store from '@/store'

const routes = [
  {
    path: '/login',
    component: AppLogin,
    name: 'AppLogin',
  },
  ...Public.routes,
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.getters['auth/isLoggedIn']) {
    if (store.getters['auth/hasCookie']) {
      store.dispatch('auth/renew').then(() => {
        next()
      })
    } else {
      router.replace({
        name: 'AppLogin',
        query: { redirect: to.fullPath },
      })
    }
  } else {
    next()
  }
})

export default router
