<template>
  <div class="container-fluid">
    <div v-if="!isLoading" class="mx-md-2 mx-xl-3">
      <h4 class="m-0 p-0 px-1 pb-2 p-md-2 py-lg-3">
        <i class="bi-collection-play-fill me-2"></i>番組一覧
      </h4>
      <div class="row row-cols-1 row-cols-md-3 row-cols-xxl-4 g-2 gy-3">
        <div v-for="program in programs" :key="program.id" class="col">
          <div class="card h-100">
            <router-link
              :to="{
                name: 'PublicProgramDetail',
                params: { permalink: program.permalink },
              }"
              class="text-decoration-none text-body stretched-link"
            >
              <span class="ratio ratio-16x9 bg-dark d-block">
                <img
                  :src="displayImage(program.file_path, program.file_type)"
                  class="card-img-top"
                  :alt="program.title"
                />
              </span>
            </router-link>
            <div class="card-body">
              <h6 class="card-title">
                <span v-if="program.broadcast_type == 'radio'"
                  ><i class="bi bi-mic"></i
                ></span>
                <span v-if="program.broadcast_type == 'tv'"
                  ><i class="bi bi-tv"></i
                ></span>
                {{ program.title }}
              </h6>
              <p class="p-0 m-0 mb-2 small">
                <span
                  :class="
                    program.type === 'premium'
                      ? 'text-bg-primary'
                      : 'text-bg-light'
                  "
                  class="badge"
                  >{{ program.type_name }}</span
                >
              </p>
              <p class="card-text small">
                {{ textWrap(program.excerpt, 30) }}
              </p>
            </div>
            <div class="card-footer lh-sm">
              <span class="text-body-secondary" style="font-size: 75%">{{
                program.short_message
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center my-3 my-md-4 my-xl-5">
        <Pagination
          :count="count"
          :prev="prev"
          :next="next"
          :current_page="current_page"
          :total_pages="total_pages"
          :page_range="5"
          :show_count="false"
          @move-page="movePage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { displayImage } from '@/utils/fileTypeUtils.js'
import { textWrap } from '@/utils/stringUtils.js'
import Pagination from '@/components/AppPagination.vue'

export default {
  name: 'PublicProgramList',
  components: {
    Pagination,
  },

  data() {
    return {
      isLoading: true,
      query: {},
    }
  },
  computed: {
    ...mapGetters({
      programs: 'public_program/programs',
      count: 'public_program/count',
      prev: 'public_program/prev',
      next: 'public_program/next',
      current_page: 'public_program/current_page',
      total_pages: 'public_program/total_pages',
      query_params: 'public_program/query_params',
    }),
  },

  async created() {
    await this.getData().finally(() => {
      this.isLoading = false
    })
    this.query = this.query_params
  },

  methods: {
    displayImage,
    textWrap,
    ...mapActions({
      setQuery: 'public_program/setQuery',
      getData: 'public_program/fetchAll',
    }),
    movePage(page) {
      this.setQuery(Object.assign(this.query, { page: page })).then(() => {
        this.getData()
      })
    },
    getQueryResult() {
      this.setQuery(this.query).then(() => {
        this.getData()
      })
    },
  },
}
</script>
