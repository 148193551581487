<template>
  <ProgramRecommend />
  <div class="container-fluid">
    <div class="mb-3 mx-md-2 mx-xl-3">
      <div class="my-4">
        <PageBanner />
      </div>
      <div class="mt-3">
        <h4><i class="bi bi-broadcast me-2"></i>本日の放送予定</h4>
        <ProgramTimetableDay>
          <p>本日の放送予定はありません。</p>
        </ProgramTimetableDay>
      </div>
    </div>
  </div>
</template>

<script>
import ProgramRecommend from '@/components/ProgramRecommend.vue'
import ProgramTimetableDay from '@/components/ProgramTimetableDay.vue'
import PageBanner from '@/components/PageBanner.vue'

export default {
  name: 'PublicHome',
  components: {
    ProgramRecommend,
    ProgramTimetableDay,
    PageBanner,
  },
}
</script>
