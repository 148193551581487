/**
 * デバッグに関するユーティリティ関数
 * @module debugUtils
 */

/**
 * 開発環境でのみログを出力する
 *
 * @param {string} [string] 出力する文字列
 * @returns {void}
 */
export const debugLog = (string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(string)
  }
}

export default {
  debugLog,
}
