<template>
  <main class="d-flex justify-content-center align-items-center">
    <h1>Not Found</h1>
  </main>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
}
</script>

<style scoped>
main {
  height: 100vh;
}
</style>
