<template>
  <main class="position-absolute top-50 start-50 translate-middle">
    <div class="text-center">
      <div class="mb-3">
        <AppLogo
          svg-width="40%"
          svg-height="auto"
          stroke-color="#fff"
          fill-color="#fff"
        />
      </div>
      <div>
        <a :href="lineAuthUrl" class="w-100 btn corporate-line"
          >LINEでログイン</a
        >
        <router-link
          :to="{
            name: 'PublicHome',
          }"
          class="w-100 mt-2 btn btn-link link-secondary"
          >戻る</router-link
        >
        <p class="mt-3 text-muted small">&copy; {{ appName }}</p>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AppLogo from '@/components/AppLogo.vue'

export default {
  name: 'AppLogin',
  components: {
    AppLogo,
  },

  computed: {
    ...mapGetters({
      lineAuthUrl: 'auth/lineAuthUrl',
    }),
    appName() {
      return process.env.VUE_APP_TITLE
    },
  },

  created() {
    this.getLineAuthUrl({
      redirect_uri: window.location.origin + this.$route.path,
    })
  },
  beforeMount() {
    const code = this.$route.query.code
    const state = this.$route.query.state
    if (code && state) {
      this.getListenerData(code, state)
    }
  },

  methods: {
    ...mapActions({
      getLineAuthUrl: 'auth/getLineAuthUrl',
      getLineProfile: 'auth/getLineProfile',
    }),
    getListenerData(code, state) {
      this.getLineProfile({
        code: code,
        state: state,
        redirect_uri: window.location.origin + this.$route.path,
      }).then(() => {
        this.$router.push({
          name: 'PublicNotifyList',
        })
      })
    },
  },
}
</script>

<style scoped>
.corporate-line {
  background-color: rgba(0, 195, 0, 1);
}
.corporate-line:hover {
  background-color: rgba(0, 195, 0, 0.5);
}
</style>
