<template>
  <div class="container-fluid">
    <div v-if="!isLoading" class="mx-md-2 mx-xl-3">
      <h4 class="m-0 p-0 px-1 pb-2 p-md-2 py-lg-3">
        <i class="bi-bell-fill me-2"></i>通知一覧
      </h4>

      <p>
        番組の放送開始前にLINEが提供する公式アカウント【LINE
        Notify】からメッセージが届きます。
      </p>

      <div v-if="!hasNotifyToken" class="alert alert-info" role="alert">
        <a :href="lineNotifyAuthUrl" class="alert-link">LINE Notify</a>
        への通知を許可してください。
      </div>

      <ul v-if="this.notifyList.length > 0" class="list-group">
        <li
          v-for="notify in notifyList"
          :key="notify.id"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          {{ notify.title }}
          <a href="#" @click="deleteNotify(notify.id)" class="text-danger"
            ><i class="bi bi-bell-slash"></i
          ></a>
        </li>
      </ul>
      <div v-else>
        <p>登録している番組はありません。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NotifyList',
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters({
      notifyList: 'public_notify/list',
      user: 'auth/user',
      lineNotifyAuthUrl: 'auth/lineNotifyAuthUrl',
    }),
    hasNotifyToken() {
      return this.user.has_notify_token
    },
  },

  async created() {
    this.getLineNotifyAuthUrl({
      redirect_uri: window.location.origin + this.$route.path,
    })
    await this.getData()
      .catch(() => {
        this.$router.push({ name: 'AppLogin' })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  beforeMount() {
    const code = this.$route.query.code
    const state = this.$route.query.state
    if (code && state) {
      this.getAccessToken(code, state)
    }
  },

  methods: {
    ...mapActions({
      getLineNotifyAuthUrl: 'auth/getLineNotifyAuthUrl',
      getLineNotifyAccessToken: 'auth/getLineNotifyAccessToken',
      getData: 'public_notify/fetchList',
      deleteNotify: 'public_notify/deleteData',
    }),
    getAccessToken(code, state) {
      this.getLineNotifyAccessToken({
        code: code,
        state: state,
        redirect_uri: window.location.origin + this.$route.path,
      }).then(() => {
        this.$router.push({
          name: this.$route.name,
        })
      })
    },
  },
}
</script>
