import api from '@/api'

// State（状態）
// https://next.vuex.vuejs.org/ja/guide/state.html
// 状態を監視するデータ
const state = () => ({
  list: [],
})

// Getters（算出プロパティ）
// https://next.vuex.vuejs.org/ja/guide/getters.html
// コンポーネントから状態を取得するプロパティまたは関数
const getters = {
  list: (state) => state.list,
}

// Mutations（状態変更）
// https://next.vuex.vuejs.org/ja/guide/mutations.html
// 状態を変更するメソッド
const mutations = {
  setList: (state, list) => (state.list = list),
  clearList: (state) => (state.list = []),
}

// Actions（処理）
// https://next.vuex.vuejs.org/ja/guide/actions.html
// コンポーネントからミューテーションを実行する関数
const actions = {
  /**
   * すべてのデータをリセットする
   *
   * @return {void}
   */
  clearAll({ commit }) {
    return new Promise((resolve) => {
      commit('clearList')
      resolve()
    })
  },
  /**
   * リストを取得する
   *
   * @return {void}
   */
  async fetchList({ state, commit }) {
    const dayjs = require('dayjs')
    // 本日の日付を取得
    let today = dayjs()
    // 3日後の日付を計算
    let threeDaysLater = today.add(6, 'day')

    let params = {
      start_date: today.format('YYYY-MM-DD'),
      end_date: threeDaysLater.format('YYYY-MM-DD'),
    }

    try {
      const response = await api.get('public/broadcasts/list/', {
        params: params,
      })
      commit('setList', response.data)
    } catch (error) {
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
